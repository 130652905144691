import { MutationTree } from 'vuex';
import { AppMutationTypes } from './mutation-types';
import { AppState as State, ThemeModes, defaultState } from './state';

export type Mutations<S = State> = {
  [AppMutationTypes.RESET](state: S): void;

  [AppMutationTypes.SET_THEME_MODE](state: S, mode: ThemeModes): void;

  [AppMutationTypes.SET_DIRT](state: S, dirt: boolean): void;
  [AppMutationTypes.SET_DIRT_STAY_RESOLVE](state: S, func: (vale?:any) => void): void;
  [AppMutationTypes.SET_DIRT_STAY_REJECT](state: S, func: (vale?:any) => void): void;
  [AppMutationTypes.SET_SHOW_DIRT_MODAL](state: S, show: boolean): void;

  [AppMutationTypes.SET_API_VERSION](state: S, apiVersion: string): void;

  [AppMutationTypes.SET_USER_MODE](state: S, mode: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [AppMutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [AppMutationTypes.SET_THEME_MODE](state: State, mode: ThemeModes) {
    state.mode = mode
  },

  [AppMutationTypes.SET_DIRT](state: State, dirt: boolean) {
    state.dirt = dirt
  },
  [AppMutationTypes.SET_DIRT_STAY_RESOLVE](state: State, func: (vale?:any) => void) {
    state.dirtyShowResolve = func
  },
  [AppMutationTypes.SET_DIRT_STAY_REJECT](state: State, func: (vale?:any) => void) {
    state.dirtyShowReject = func
  },
  [AppMutationTypes.SET_SHOW_DIRT_MODAL](state: State, show: boolean) {
    state.dirtModal = show
  },

  [AppMutationTypes.SET_API_VERSION](state: State, apiVersion: string) {
    state.apiVersion = apiVersion
  },

  [AppMutationTypes.SET_USER_MODE](state: State, mode: boolean) {
    state.userMode = mode
  },
};
