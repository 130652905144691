import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { OrganisationActionTypes } from "./action-types";
import { Config } from "../../../config";
import { Mutations } from "./mutations";
import { OrganisationMutationTypes } from "./mutation-types";
import { HttpClient as http, AxiosError, AxiosResponse } from "@/util/ZebritAxios";
import { omit, merge } from "lodash";
import {
  Organisation,
  OrganisationSupportEmailIMAP,
  User,
  FieldDef,
  SortDef,
  FilterDef,
  UserListFields,
} from "@/types/index";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [OrganisationActionTypes.LOAD_ORGANIZATION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [OrganisationActionTypes.SAVE_ORGANIZATION_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Organisation
  ): void;

  [OrganisationActionTypes.TEST_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION](
    { commit }: AugmentedActionContext,
    payload: OrganisationSupportEmailIMAP
  ): void;

  [OrganisationActionTypes.SAVE_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: OrganisationSupportEmailIMAP
  ): void;

  [OrganisationActionTypes.LOAD_USERS_ACTION]({ commit }: AugmentedActionContext): void;

  [OrganisationActionTypes.SET_USERS_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_USERS_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_USERS_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_USERS_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [OrganisationActionTypes.SET_USERS_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [OrganisationActionTypes.SET_USERS_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [OrganisationActionTypes.SET_USERS_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [OrganisationActionTypes.LOAD_GROUPS_ACTION]({ commit }: AugmentedActionContext): void;

  [OrganisationActionTypes.SET_GROUPS_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_GROUPS_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_GROUPS_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_GROUPS_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [OrganisationActionTypes.SET_GROUPS_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [OrganisationActionTypes.SET_GROUPS_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [OrganisationActionTypes.SET_GROUPS_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [OrganisationActionTypes.LOAD_ROLES_ACTION]({ commit }: AugmentedActionContext): void;

  [OrganisationActionTypes.LOAD_PERMISSIONS_ACTION]({
    commit,
  }: AugmentedActionContext): void;

  [OrganisationActionTypes.SET_ROLES_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_ROLES_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_ROLES_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_ROLES_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;
  [OrganisationActionTypes.SET_ROLES_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ): void;
  [OrganisationActionTypes.SET_ROLES_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ): void;
  [OrganisationActionTypes.SET_ROLES_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ): void;

  [OrganisationActionTypes.LOAD_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [OrganisationActionTypes.SET_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User | null
  ): void;
  [OrganisationActionTypes.SAVE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User
  ): void;
  [OrganisationActionTypes.DELETE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;

  [OrganisationActionTypes.INVITE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: {
      firstName: any;
      lastName: any;
      password: any;
      // pin: any;
      email: string;
      role: string;
    }
  ): void;

  [OrganisationActionTypes.LOAD_GROUP_ACTION]({ commit }: AugmentedActionContext): void;
  [OrganisationActionTypes.SAVE_GROUP_ACTION]({ commit }: AugmentedActionContext): void;
  [OrganisationActionTypes.DELETE_GROUP_ACTION]({ commit }: AugmentedActionContext): void;

  [OrganisationActionTypes.LOAD_ROLE_ACTION]({ commit }: AugmentedActionContext): void;
  [OrganisationActionTypes.SAVE_ROLE_ACTION]({ commit }: AugmentedActionContext): void;
  [OrganisationActionTypes.DELETE_ROLE_ACTION]({ commit }: AugmentedActionContext): void;

  [OrganisationActionTypes.LOAD_ADMIN_ORGANIZATION_ACTION](
      { commit }: AugmentedActionContext,
      payload: string
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async [OrganisationActionTypes.LOAD_ORGANIZATION_ACTION](
    { commit }: AugmentedActionContext,
    payload: string
  ) {
    commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, true);
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.organisation + `/${payload}`)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ORGANIZATION, null);
            commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ORGANIZATION, response.data);
            commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
          console.log(err);
          reject(err);
        });
    });
  },

  async [OrganisationActionTypes.SAVE_ORGANIZATION_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: Organisation
  ) {
    commit(OrganisationMutationTypes.SET_SAVING_ORGANIZATION, true);
    commit(OrganisationMutationTypes.SET_SAVE_ORGANIZATION_ERROR, null);

    let _payload = JSON.parse(JSON.stringify(payload));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    _payload = omit(_payload, [
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "logoUrl",
    ]);

    await http
      .put(Config.api.organisation.organisation + `/${_payload._id}`, _payload)
      .then((response: AxiosResponse) => {
        commit(OrganisationMutationTypes.SET_ORGANIZATION, response.data);
        commit(OrganisationMutationTypes.SET_SAVING_ORGANIZATION, false);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANIZATION_ERROR, null);
        dispatch(OrganisationActionTypes.LOAD_ORGANIZATION_ACTION, _payload._id);
      })
      .catch((err: AxiosError) => {
        commit(OrganisationMutationTypes.SET_SAVING_ORGANIZATION, false);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANIZATION_ERROR, err.message);
        console.log("error", err);
      });
  },

  async [OrganisationActionTypes.TEST_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION](
    { commit }: AugmentedActionContext,
    payload: OrganisationSupportEmailIMAP
  ) {
    commit(OrganisationMutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL, true);
    commit(OrganisationMutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR, null);
    commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);

    commit(OrganisationMutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR, null);
    commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);

    let _payload = JSON.parse(JSON.stringify(payload));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    await http
      .put(Config.api.organisation.testsupportemail + `/${_payload._id}`, _payload)
      .then((response: AxiosResponse) => {
        commit(OrganisationMutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL, true);
        commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR, null);
        commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);
      })
      .catch((err: any) => {
        commit(OrganisationMutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR, err?.data?.message);
        commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, merge(err?.data?.validation, { message: err?.data?.message }));
        console.log("error", err);
      });
  },

  async [OrganisationActionTypes.SAVE_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION](
    { commit, dispatch }: AugmentedActionContext,
    payload: OrganisationSupportEmailIMAP
  ) {
    commit(OrganisationMutationTypes.SET_TESTING_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_TESTED_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAILERROR, null);
    commit(OrganisationMutationTypes.SET_TEST_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);

    commit(OrganisationMutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL, true);
    commit(OrganisationMutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL, false);
    commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR, null);
    commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);

    let _payload = JSON.parse(JSON.stringify(payload));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    await http
      .put(Config.api.organisation.savesupportemail + `/${_payload._id}`, _payload)
      .then((response: AxiosResponse) => {
        commit(OrganisationMutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL, true);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR, null);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, null);
      })
      .catch((err: any) => {
        commit(OrganisationMutationTypes.SET_SAVING_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_SAVED_ORGANISATION_SUPPORT_EMAIL, false);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAILERROR, err?.data?.message);
        commit(OrganisationMutationTypes.SET_SAVE_ORGANISATION_SUPPORT_EMAIL_VALIDATION_ERROR, merge(err?.data?.validation, { message: err?.data?.message }));
        console.log("error", err);
      });
  },

  async [OrganisationActionTypes.LOAD_USERS_ACTION]({ commit, getters }) {
    commit(OrganisationMutationTypes.SET_LOADING_USERS, true);

    const page = getters.StateUsersPage;
    const page_size = getters.StateUsersPageSize;
    const search = getters.StateUsersSearch;
    const sorts = getters.StateUsersSorts;

    const params: any = {
      $page: page,
      $limit: page_size
    };

    if (search) {
      params.$term = search;
      const searchFields = UserListFields.filter((uf) => uf.searchable === true).map(
        (uf) => uf.id
      );
      params.$searchFields = searchFields;
    }

    if (sorts && sorts.length > 0) {
      params["$sort"] = (sorts[0].direction == "desc" ? "-" : "") + sorts[0].field.id;
    }

    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.users, params, {
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res: any) => {
          if (res && res.isAxiosError) {
            reject(res.response.data.message);
          } else {
            res.data.docs.forEach((u: any) => (u.role = u.role.name));
            commit(OrganisationMutationTypes.SET_USERS, res.data.docs);
            commit(OrganisationMutationTypes.SET_USERS_PAGE, res.data.pages.current);
            commit(OrganisationMutationTypes.SET_USERS_PAGESIZE, res.data.items.limit);
            commit(OrganisationMutationTypes.SET_USERS_TOTAL, res.data.items.total);

            commit(OrganisationMutationTypes.SET_LOADING_USERS, false);
            commit(OrganisationMutationTypes.SET_USERS_LOADED, true);
            resolve(res);
          }
        })
        .catch((err: AxiosError) => {
          console.log("error", err);
          commit(OrganisationMutationTypes.SET_LOADING_USERS, false);
          commit(OrganisationMutationTypes.SET_USERS_LOADED, false);
          reject(err);
        });
    });
  },

  [OrganisationActionTypes.SET_USERS_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_USERS_PAGE, payload);
  },

  [OrganisationActionTypes.SET_USERS_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_USERS_PAGESIZE, payload);
  },
  [OrganisationActionTypes.SET_USERS_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_USERS_TOTAL, payload);
  },
  [OrganisationActionTypes.SET_USERS_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(OrganisationMutationTypes.SET_USERS_SEARCH, payload);
  },
  [OrganisationActionTypes.SET_USERS_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(OrganisationMutationTypes.SET_USERS_FILTER, payload);
  },
  [OrganisationActionTypes.SET_USERS_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(OrganisationMutationTypes.SET_USERS_AVAILABLEFILEDS, payload);
  },
  [OrganisationActionTypes.SET_USERS_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(OrganisationMutationTypes.SET_USERS_SORTS, payload);
  },

  async [OrganisationActionTypes.LOAD_GROUPS_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.groups)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_GROUPS, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_GROUPS, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  [OrganisationActionTypes.SET_GROUPS_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_PAGE, payload);
  },

  [OrganisationActionTypes.SET_GROUPS_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_PAGESIZE, payload);
  },
  [OrganisationActionTypes.SET_GROUPS_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_TOTAL, payload);
  },
  [OrganisationActionTypes.SET_GROUPS_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_SEARCH, payload);
  },
  [OrganisationActionTypes.SET_GROUPS_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_FILTER, payload);
  },
  [OrganisationActionTypes.SET_GROUPS_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_AVAILABLEFILEDS, payload);
  },
  [OrganisationActionTypes.SET_GROUPS_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(OrganisationMutationTypes.SET_GROUPS_SORTS, payload);
  },

  async [OrganisationActionTypes.LOAD_ROLES_ACTION]({ commit }) {
    commit(OrganisationMutationTypes.SET_LOADING_ROLES, true);
    commit(OrganisationMutationTypes.SET_ROLES_LOADED, false);

    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.roles)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            commit(OrganisationMutationTypes.SET_ROLES, []);
            commit(OrganisationMutationTypes.SET_LOADING_ROLES, false);
            commit(OrganisationMutationTypes.SET_ROLES_LOADED, false);

            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data.docs);
            commit(OrganisationMutationTypes.SET_LOADING_ROLES, false);
            commit(OrganisationMutationTypes.SET_ROLES_LOADED, true);

            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [OrganisationActionTypes.LOAD_PERMISSIONS_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.permissions)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_PERMISSIONS, null);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_PERMISSIONS, response.data.docs);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  [OrganisationActionTypes.SET_ROLES_PAGE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_PAGE, payload);
  },

  [OrganisationActionTypes.SET_ROLES_PAGESIZE_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_PAGESIZE, payload);
  },
  [OrganisationActionTypes.SET_ROLES_TOTAL_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_TOTAL, payload);
  },
  [OrganisationActionTypes.SET_ROLES_SEARCH_ACTION](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_SEARCH, payload);
  },
  [OrganisationActionTypes.SET_ROLES_FILTER_ACTION](
    { commit }: AugmentedActionContext,
    payload: FilterDef[]
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_FILTER, payload);
  },
  [OrganisationActionTypes.SET_ROLES_AVAILABLEFILEDS_ACTION](
    { commit }: AugmentedActionContext,
    payload: FieldDef[]
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_AVAILABLEFILEDS, payload);
  },
  [OrganisationActionTypes.SET_ROLES_SORTS_ACTION](
    { commit }: AugmentedActionContext,
    payload: SortDef[]
  ) {
    commit(OrganisationMutationTypes.SET_ROLES_SORTS, payload);
  },

  [OrganisationActionTypes.LOAD_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.user + `/${payload}`)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_CRUD_FOCUSED_USER, null);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_CRUD_FOCUSED_USER, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.SET_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User | null
  ) {
    commit(OrganisationMutationTypes.SET_CRUD_FOCUSED_USER, payload);
  },
  [OrganisationActionTypes.SAVE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: User
  ) {
    let _payload = JSON.parse(JSON.stringify(payload));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== null)
    );
    _payload = Object.fromEntries(Object.entries(_payload).filter(([id, v]) => v !== ""));
    _payload = Object.fromEntries(
      Object.entries(_payload).filter(([id, v]) => v !== undefined)
    );

    _payload = omit(_payload, [
      "isActive",
      "createdAt",
      "createdBy",
      "isEnabled",
      "passwordUpdateRequired",
      "pinUpdateRequired",
      "updatedAt",
      "updatedBy",
      "roleName",
      "roleRank",
      "avatarUrl",
      "groups",
      "subscription",
      "hasSubscription"
    ]);

    if (payload._id === -1)
      _payload = omit(_payload, ["_id"]);

    commit(OrganisationMutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER, true);
    commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA, null);

    return new Promise(function (resolve, reject) {
      (payload._id === -1
        ? http.post(Config.api.organisation.user, _payload)
        : http.put(Config.api.organisation.user + "/" + payload._id, _payload)
      )
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER, false);
            commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED, false);
            commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA, response);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_CRUD_FOCUSED_USER, response.data);
            commit(OrganisationMutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER, false);
            commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED, true);
            commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA, null);
            resolve(response);
          }
        })
        .catch((err: any) => {
          console.log(err);
          commit(OrganisationMutationTypes.SET_IS_CREATING_CRUD_FOCUSED_USER, false);
          commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_SUCCEEDED, false);
          commit(OrganisationMutationTypes.SET_CREATING_CRUD_FOCUSED_USER_DATA, err?.data?.message);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.DELETE_USER_ACTION](
    { commit }: AugmentedActionContext,
    payload: number
  ) {
    return new Promise(function (resolve, reject) {
      http
        .delete(Config.api.organisation.user + `/${payload}`)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  [OrganisationActionTypes.LOAD_GROUP_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.group)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.SAVE_GROUP_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.group)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.DELETE_GROUP_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.group)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  [OrganisationActionTypes.LOAD_ROLE_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.role)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.SAVE_ROLE_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.role)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },
  [OrganisationActionTypes.DELETE_ROLE_ACTION]({ commit }) {
    return new Promise(function (resolve, reject) {
      http
        .get(Config.api.organisation.role)
        .then((response: any) => {
          if (response.isAxiosError || response.data.error) {
            commit(OrganisationMutationTypes.SET_ROLES, []);
            resolve(response);
          } else {
            commit(OrganisationMutationTypes.SET_ROLES, response.data);
            resolve(response);
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
          reject(err);
        });
    });
  },

  async [OrganisationActionTypes.INVITE_USER_ACTION]({ commit }, payload) {
    const invitePayload = {
      user: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        role: payload.role,
      },
      registerType: "Invite",
    };

    await http
      .post(Config.api.account.registrationStep1, invitePayload)
      .then((response: any) => {
        if (response && (response.isAxiosError || response.data.error)) {
          if (response.data) {
            commit(OrganisationMutationTypes.SET_INVITE_ERROR, response.data.error);
            commit(
              OrganisationMutationTypes.SET_INVITE_ERROR_MESSAGE,
              response.data.message
            );
          } else if (response.isAxiosError) {
            commit(
              OrganisationMutationTypes.SET_INVITE_ERROR,
              response.response.status
            );
            commit(
              OrganisationMutationTypes.SET_INVITE_ERROR_MESSAGE,
              response.response.data.message
            );
          }
        } else {
          commit(OrganisationMutationTypes.SET_INVITE_ERROR, null);
          commit(OrganisationMutationTypes.SET_INVITE_ERROR_MESSAGE, null);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  },

  async [OrganisationActionTypes.LOAD_ADMIN_ORGANIZATION_ACTION](
      { commit }: AugmentedActionContext,
      payload: string
  ) {
    commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, true);
    return new Promise(function (resolve, reject) {
      http
          .get(Config.api.organisation.organisationAdmin + `/${payload}`)
          .then((response: any) => {
            if (response.isAxiosError || response.data.error) {
              commit(OrganisationMutationTypes.SET_ORGANIZATION, null);
              commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
              resolve(response);
            } else {
              commit(OrganisationMutationTypes.SET_ORGANIZATION, response.data);
              commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
              resolve(response);
            }
          })
          .catch((err: AxiosError) => {
            commit(OrganisationMutationTypes.SET_LOADING_ORGANIZATION, false);
            console.log(err);
            reject(err);
          });
    });
  },
};
