import { MutationTree } from 'vuex';
// import { State } from './state';
// import { KnowledgeMutationTypes as MutationTypes, KnowledgeState as State } from '@/types/index';
import { KnowledgeState as State, defaultState } from './state';
import { KnowledgeMutationTypes as MutationTypes } from './mutation-types';
import {
  Knowledge,
  KnowledgeCategory,
  FieldDef,
  FilterDef,
  SortDef
} from '@/types/index';


export type Mutations<S = State> = {

  [MutationTypes.RESET](state: S): void;

  [MutationTypes.SET_KNOWLEDGES](state: S, payload: Knowledge[]): void;
  [MutationTypes.SET_IS_LOADING_KNOWLEDGES](state: S, payload: boolean): void;

  [MutationTypes.SET_PAGE](state: S, payload: number): void;
  [MutationTypes.SET_PAGESIZE](state: S, payload: number): void;
  [MutationTypes.SET_TOTAL](state: S, payload: number): void;
  [MutationTypes.SET_SEARCH](state: S, payload: string | null): void;
  [MutationTypes.SET_FILTER](state: S, payload: FilterDef[]): void;
  [MutationTypes.SET_AVAILABLEFIELDS](state: S, payload: FieldDef[]): void;
  [MutationTypes.SET_SORTFIELDS](state: S, payload: SortDef[]): void;

  [MutationTypes.SET_SEARCHKNOWLEDGES](state: S, payload: Knowledge[]): void;
  [MutationTypes.SET_IS_LOADING_SEARCH_KNOWLEDGES](state: S, payload: boolean): void;

  [MutationTypes.SET_KNOWLEDGE](state: S, payload: Knowledge | null): void;
  [MutationTypes.SET_IS_LOADING_KNOWLEDGE](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CREATING_KNOWLEDGE](state: S, payload: boolean): void;
  [MutationTypes.SET_CREATED_KNOWLEDGE_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_UPDATING_KNOWLEDGE](state: S, payload: boolean): void;
  [MutationTypes.SET_UPDATED_KNOWLEDGE_DATA](state: S, payload: any): void;
  [MutationTypes.SET_IS_DELETING_KNOWLEDGE](state: S, payload: boolean): void;

  [MutationTypes.SET_DELETED_KNOWLEDGE_SUCCEEDED](state: S, payload: boolean): void;
  [MutationTypes.SET_DELETED_KNOWLEDGE_DATA](state: S, payload: any): void;

  [MutationTypes.SET_KNOWLEDGE_TO_PREVIEW_MOD](state: S, payload: string | null): void;
  [MutationTypes.SET_KNOWLEDGE_PREVIEW_OPEN_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_KNOWLEDGE_CATEGORIES_OPEN_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_CATEGORY](state: S, payload: KnowledgeCategory | null): void;
  [MutationTypes.SET_CATEGORIES](state: S, payload: KnowledgeCategory[]): void;
  [MutationTypes.SET_IS_LOADING_CATEGORIES](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_CATEGORIES_LOADED](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.RESET](state) {
    Object.assign(state, defaultState())
    // this.replaceState(defaultState())
  },

  [MutationTypes.SET_KNOWLEDGES](state: State, knowledges: Knowledge[]) {
    state.knowledges = knowledges
  },
  [MutationTypes.SET_IS_LOADING_KNOWLEDGES](state: State, isLoadingKnowledges: boolean) {
    state.isLoadingKnowledges = isLoadingKnowledges;
  },

  [MutationTypes.SET_PAGE](state: State, page: number) {
    state.page = page;
  },

  [MutationTypes.SET_PAGESIZE](state: State, pageSize: number) {
    state.pageSize = pageSize;
  },

  [MutationTypes.SET_TOTAL](state: State, total: number) {
    state.total = total;
  },

  [MutationTypes.SET_SEARCH](state: State, search: string | null) {
    state.search = search;
  },

  [MutationTypes.SET_FILTER](state: State, filters: FilterDef[]) {

    state.filters = filters;
  },

  [MutationTypes.SET_AVAILABLEFIELDS](state: State, availableFields: FieldDef[]) {
    state.availableFields = availableFields;
  },

  [MutationTypes.SET_SORTFIELDS](state: State, sortFields: SortDef[]) {
    state.sortFields = sortFields;
  },


  [MutationTypes.SET_SEARCHKNOWLEDGES](state: State, searchKnowledges: Knowledge[]) {
    state.searchKnowledges = searchKnowledges
  },
  [MutationTypes.SET_IS_LOADING_SEARCH_KNOWLEDGES](state: State, isLoadingSearchKnowledges: boolean) {
    state.isLoadingSearchKnowledges = isLoadingSearchKnowledges;
  },


  [MutationTypes.SET_KNOWLEDGE](state: State, knowledge: Knowledge | null) {
    state.knowledge = knowledge === null ? null : Knowledge.fromObject(knowledge);
  },
  [MutationTypes.SET_IS_LOADING_KNOWLEDGE](state: State, isLoadingKnowledge: boolean) {
    state.isLoadingKnowledge = isLoadingKnowledge;
  },

  [MutationTypes.SET_IS_CREATING_KNOWLEDGE](state: State, isCreatingAsset: boolean) {
    state.isCreatingKnowledge = isCreatingAsset;
  },
  [MutationTypes.SET_CREATED_KNOWLEDGE_DATA](state: State, createdKnowledgeData: any) {
    state.createdKnowledgeData = createdKnowledgeData;
  },
  [MutationTypes.SET_IS_UPDATING_KNOWLEDGE](state: State, isUpdatingKnowledge: boolean) {
    state.isUpdatingKnowledge = isUpdatingKnowledge;
  },
  [MutationTypes.SET_UPDATED_KNOWLEDGE_DATA](state: State, updatedKnowledgeData: any) {
    state.updatedKnowledgeData = updatedKnowledgeData;
  },
  [MutationTypes.SET_IS_DELETING_KNOWLEDGE](state: State, isDeletingKnowledge: boolean) {
    state.isDeletingKnowledge = isDeletingKnowledge;
  },
  [MutationTypes.SET_DELETED_KNOWLEDGE_SUCCEEDED](state: State, deletedKnowledgeSucceeded: boolean) {
    state.deletedKnowledgeSucceeded = deletedKnowledgeSucceeded;
  },
  [MutationTypes.SET_DELETED_KNOWLEDGE_DATA](state: State, deletedKnowledgeData: any) {
    state.deletedKnowledgeData = deletedKnowledgeData;
  },

  [MutationTypes.SET_KNOWLEDGE_TO_PREVIEW_MOD](state: State, knowledgeToPreviewMod: string | null) {
    state.knowledgeToPreviewMod = knowledgeToPreviewMod;
  },
  [MutationTypes.SET_KNOWLEDGE_PREVIEW_OPEN_STATE](state: State, knowledgePreviewOpenState: boolean) {
    state.knowledgePreviewOpenState = knowledgePreviewOpenState;
  },

  [MutationTypes.SET_KNOWLEDGE_CATEGORIES_OPEN_STATE](state: State, knowledgeCategoriesOpenState: boolean) {
    state.knowledgeCategoriesOpenState = knowledgeCategoriesOpenState;
  },

  [MutationTypes.SET_CATEGORY](state: State, category: KnowledgeCategory | null) {
    state.category = category; // categories.map(category => KnowledgeCategory.fromObject(categories));
  },
  [MutationTypes.SET_CATEGORIES](state: State, categories: KnowledgeCategory[]) {
    state.categories = categories; // categories.map(category => KnowledgeCategory.fromObject(categories));
  },
  [MutationTypes.SET_IS_LOADING_CATEGORIES](state: State, isLoadingCategories: boolean) {
    state.isLoadingCategories = isLoadingCategories;
  },
  [MutationTypes.SET_IS_CATEGORIES_LOADED](state: State, isCategoriesLoaded: boolean) {
    state.isCategoriesLoaded = isCategoriesLoaded;
  },


};
