
import { defineComponent } from "vue";
import { Switch } from '@headlessui/vue'

export default defineComponent({
  name: "FormToggleV2",
  components: {
    Switch
  },
  props: {
    class: {
      type: String,
      default: '',
      required: false,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['update:modelValue'],
});
