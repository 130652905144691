// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum AppMutationTypes {
  RESET = "reset",

  SET_THEME_MODE = 'setThemeMode',

  SET_API_VERSION = 'setApiVersion',

  SET_DIRT = 'setDirt',
  SET_DIRT_STAY_RESOLVE = 'setDirtStayResolve',
  SET_DIRT_STAY_REJECT = 'setDirtStayReject',
  SET_SHOW_DIRT_MODAL = 'setShowDirtModal',

  SET_USER_MODE = 'setUserMode',
}
