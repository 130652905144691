import { GetterTree } from 'vuex';
import { RootState } from '@/store';
// import { State } from './state';
import {
  Knowledge,
  FilterDef,
  KnowledgeCategory,
  // KnowledgeGetters,
  FieldDef,
  SortDef,
  // KnowledgeState as State
} from '@/types/index';
import { KnowledgeState as State } from './state';

export type KnowledgeGetters = {
  StateKnowledges(state: State): Knowledge[];
  StateIsLoadingKnowledges(state: State): boolean;

  StatePage(state: State): number;
  StatePageSize(state: State): number;
  StateTotal(state: State): number;
  StateSearch(state: State): string | null;
  StateFilter(state: State): FilterDef[];
  StateAvailableFields(state: State): FieldDef[];
  StateSortFields(state: State): SortDef[];

  StateSearchKnowledges(state: State): Knowledge[];
  StateIsLoadingSearchKnowledges(state: State): boolean;

  StateKnowledge(state: State): Knowledge | null;
  StateIsLoadingKnowledge(state: State): boolean;
  StateIsCreating(state: State): boolean;
  StateIsUpdating(state: State): boolean;

  StateCreatedData(state: State): any;
  StateUpdatedData(state: State): any;
  StateIsDeletingKnowledge(state: State): boolean;

  StateDeletedKnowledgeSucceeded(state: State): any;
  StateDeletedKnowledgeData(state: State): any;

  StateCategory: (state: State) => KnowledgeCategory | null;
  StateCategories: (state: State) => KnowledgeCategory[];
  StateIsLoadingCategories: (state: State) => boolean;
  StateCategoriesLoaded: (state: State) => boolean;

  StateKnowledgeToPreviewMod(state: State): string | null;
  StateKnowledgePreviewOpenState(state: State): boolean;

  StateKnowledgeCategoriesOpenState(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & KnowledgeGetters = {
  StateKnowledges: (state: State) => state.knowledges,
  StateIsLoadingKnowledges: (state: State) => state.isLoadingKnowledges,

  StatePage: (state: State) => state.page,
  StatePageSize: (state: State) => state.pageSize,
  StateTotal: (state: State) => state.total,
  StateSearch: (state: State) => state.search,
  StateFilter: (state: State) => state.filters,
  StateAvailableFields: (state: State) => state.availableFields,
  StateSortFields: (state: State) => state.sortFields,

  StateSearchKnowledges: (state: State) => state.searchKnowledges,
  StateIsLoadingSearchKnowledges: (state: State) => state.isLoadingSearchKnowledges,

  StateKnowledge: (state: State) => state.knowledge,
  StateIsLoadingKnowledge: (state: State) => state.isLoadingKnowledge,
  StateIsCreating: (state: State) => state.isCreatingKnowledge,
  StateIsUpdating: (state: State) => state.isUpdatingKnowledge,
  StateCreatedData: (state: State) => state.createdKnowledgeData,
  StateUpdatedData: (state: State) => state.updatedKnowledgeData,
  StateIsDeletingKnowledge: (state: State) => state.isDeletingKnowledge,

  StateDeletedKnowledgeSucceeded: (state: State) => state.deletedKnowledgeSucceeded,
  StateDeletedKnowledgeData: (state: State) => state.deletedKnowledgeData,

  StateCategory: (state: State) => state.category,
  StateCategories: (state: State) => state.categories,
  StateIsLoadingCategories: (state: State) => state.isLoadingCategories,
  StateCategoriesLoaded: (state: State) => state.isCategoriesLoaded,

  StateKnowledgeToPreviewMod: (state: State) => state.knowledgeToPreviewMod,
  StateKnowledgePreviewOpenState: (state: State) => state.knowledgePreviewOpenState,

  StateKnowledgeCategoriesOpenState: (state: State) => state.knowledgeCategoriesOpenState,
};
