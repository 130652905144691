
import { defineComponent } from "vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import TopMenuUserProfile from "@/components/TopMenuUserProfile.vue";
import { mapGetters, mapActions } from "vuex";
// import TopMenuNotifications from "@/components/TopMenuNotifications.vue";
import TopMenuOrganisationLogo from "@/components/TopMenuOrganisationLogo.vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Bars3BottomLeftIcon, PlusSmallIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { PermissionKeys } from "@/types/index";
import { RouteRecordRaw } from "vue-router";
import { userRoles, hasPermission } from "@/composables/UserRole";
import FormToggleV2 from "@/components/FormElement/FormToggleV2.vue";

export default defineComponent({
  name: "TopMenu",
  components: {
    FormToggleV2,
    TopMenuUserProfile,
    // TopMenuNotifications,
    Bars3Icon,
    XMarkIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,

    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3BottomLeftIcon,
    PlusSmallIcon,
    MagnifyingGlassIcon,
    TopMenuOrganisationLogo
  },
  mounted() {
    // console.log('usermode', this.userMode);
    // if( this.user && this.user?.roleName && ['Admin', 'Agent'].includes(this.user.roleName) ) {
      // @todo: check route type(management or main) & user mode if not role is admin, agent then if is user mode and the current route
      // is management then redirect to service desk also if the route type is main then redirect to dashboard on admin mode
    // }
  },
  computed: {
    ...mapGetters({
      userMode: "app/StateUserMode",
      user: "auth/StateUser",
    }),
    topMenus(): RouteRecordRaw[] {
      const { user, roles } = userRoles();
      if (!user || !roles) return [];
      return this.$router.options.routes.filter((r: RouteRecordRaw) => {
        let _hasPermission = false; //undefined as RolePermission | undefined;
        if (r.meta?.permission)
          _hasPermission = hasPermission(r.meta?.permission as PermissionKeys[] | null);
          // hasPermission = userRole
          //   ? userRole?.permissions.find(
          //       (a: Permission) => a.type === r.meta?.permission
          //     )
          //   : undefined;

        let menuMode = "main"
        if( user?.roleName && ['Admin', 'Agent'].includes(user.roleName) ) {
          if(!this.userMode) menuMode = "management"
        }
        return (_hasPermission && r.meta?.type === "menu" && r.meta?.menu === menuMode);
      });
    },
  },
  methods: {
    switchUserMode() {
      if(this.userMode){
        this["app/SetUserMode"](false);
        this.$router.push({
          name: "DashBoard",
        });
      }else {
        this["app/SetUserMode"](true);
        this.$router.push({
          name: "Service Desk",
        });
      }
    },
    ...mapActions([
      "app/GetUserMode",
      "app/SetUserMode"
    ]),
  }
});
