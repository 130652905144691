export enum AuthActionTypes {
  REGISTER_STEP1_ACTION = 'RegisterStep1',
  REGISTER_STEP2_ACTION = 'RegisterStep2',
  REGISTER_STEP3_ACTION = 'RegisterStep3',
  LOGIN_ACTION = 'LogIn',
  GOOGLE_LOGIN_ACTION = 'GoogleLogIn',
  LOADCURRENTUSER_ACTION = 'LoadCurrentUser',
  // GETNOTIFICATIONS_ACTION = 'GetNotifications',
  LOGOUT_ACTION = 'LogOut',
  VERIFY_ACTION = 'Verify',

  UPDATE_TOKENS = 'updateTokens',
  USE_REFRESH_TOKEN = 'useRefreshToken',
  SET_AUTH = 'setAuth',
  CLEAR_AUTH = 'clearAuth',

  SAVE_USER_ACTION = 'SaveUser',

  SEND_FORGOT_PASSWORD_REQUEST = 'SendForgotPasswordRequest',
  SEND_RESET_PASSWORD_REQUEST = 'SendResetPasswordRequest'

}
