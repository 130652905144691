export enum AppActionTypes {
  GETMODE_ACTION = 'GetMode',
  SETMODE_ACTION = 'SetMode',

  GET_API_VERSION_ACTION = 'GetApiVersion',
  SET_API_VERSION_ACTION = 'SetApiVersion',

  SET_DIRT_ACTION = 'SetDirt',
  DIRT_CONFIRM_STAY_ACTION = 'DirtConfirmStay',
  DIRT_STAY_RESOLVE_ACTION = 'DirtStayResolve',
  DIRT_STAY_REJECT_ACTION = 'DirtStayReject',

  GETUSERMODE_ACTION = 'GetUserMode',
  SETUSERMODE_ACTION = 'SetUserMode',
}
