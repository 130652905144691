export enum KnowledgeActionTypes {
  GETKNOWLEDGES_ACTION = "GetKnowledges",
  GETSEARCHKNOWLEDGES_ACTION = "GetSearchKnowledges",
  GETKNOWLEDGE_ACTION = "GetKnowledge",
  GETKNOWLEDGE_BYID_ACTION = "GetKnowledgeById",
  SETKNOWLEDGE_ACTION = "SetKnowledge",
  SAVEKNOWLEDGE_ACTION = "SaveKnowledge",
  DELETEKNOWLEDGE_ACTION = "DeleteKnowledge",
  GET_CATEGORY_ACTION = "GetCategory",
  GET_CATEGORIES_ACTION = "GetCategories",

  SET_PAGE = "SetPage",
  SET_PAGESIZE = "SetPageSize",
  SET_TOTAL = "SetTotal",
  SET_SEARCH = "SetSearch",
  SET_FILTER = "SetFilter",
  SET_AVAILABLEFIELDS = "SetAvailableFields",
  SET_SORTFIELDS = "SetSortFields",
  SET_SEARCHKNOWLEDGES = "SetSearchKnowledges",

  SET_KNOWLEDGE_TO_PREVIEW_MOD_ACTION = "SetKnowledgeToPreviewModAction",
  SET_KNOWLEDGE_PREVIEW_OPEN_STATE_ACTION = "SetKnowledgePreviewOpenStateAction",

  SET_KNOWLEDGE_CATEGORIES_OPEN_STATE_ACTION = "SetKnowledgeCategoriesOpenStateAction",
}
