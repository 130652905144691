import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { store } from "@/store";
import { OPermissionKeys, PermissionKeys } from "@/types";
import {
  HomeIcon,
  ArrowLeftOnRectangleIcon,
  UserCircleIcon,
  CubeTransparentIcon,
  TicketIcon,
  CogIcon,
  AcademicCapIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  BanknotesIcon,
  PresentationChartBarIcon,
  BriefcaseIcon,
  BookOpenIcon,
} from "@heroicons/vue/24/outline";

import { userRoles, hasPermission } from "@/composables/UserRole";
import { paymentComposable } from "@/composables/Payment";

interface IAssetActionParam {
  singleAssetId: string | string[];
  back: string | string[] | null;
  singleAssetAction: string;
}

interface IAssetFieldActionParam {
  singleAssetFieldId: string | string[];
  back: string | string[] | null;
  singleAssetFieldAction: string;
}

interface IServiceCatalogueItemActionParam {
  singleServiceCatalogueItemId: string | string[];
  back: string | string[] | null;
  singleServiceCatalogueItemAction: string;
}

interface ITicketActionParam {
  singleTicketId: string | string[];
  back: string | string[] | null;
  singleTicketAction: string;
}

interface IStaffingActionParam {
  singleStaffingId: string | string[];
  singleStaffingAction: string;
}

interface IArticleActionParam {
  singleArticleId: string | string[];
  back: string | string[] | null;
  singleArticleAction: string;
}

interface IKnowledgeActionParam {
  knowledgeId: string | string[];
  knowledgeAction: string;
}

interface ISettingsUserActionParam {
  singleUserId: string | string[];
  back: string | string[] | null;
  singleUserAction: string;
}

interface IRegisterActivateParam {}

interface INewTicketActionParam {}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Service Desk",
    components: {
      default: () => import(/* webpackChunkName: "assets" */ "../views/ServiceDesk.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: "main",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.serviceDesk], //OPermissionKeys.ServiceDesk,
    },
  },
  {
    path: "/dashboard",
    name: "DashBoard",
    components: {
      default: () => import(/* webpackChunkName: "dashboard" */ "../views/DashBoard.vue"),
    },
    meta: {
      icon: PresentationChartBarIcon,
      menu: "management",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.dashboard],
    },
  },
  {
    path: "/tickets",
    name: "Tickets",
    components: {
      default: () => import(/* webpackChunkName: "tickets" */ "../views/Tickets.vue"),
    },
    meta: {
      icon: TicketIcon,
      menu: "management",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.ticket],
    },
  },
  {
    path: "/ticket/:id/:back?",
    name: "Ticket",
    components: {
      default: () => import(/* webpackChunkName: "tickets" */ "../views/Ticket.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): ITicketActionParam => {
        return {
          singleTicketId: route.params["id"],
          back: route.params["back"] ? route.params["back"] : null,
          singleTicketAction: "edit",
        };
      },
    },
    meta: {
      icon: TicketIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.ticket, OPermissionKeys.ticketMine], //OPermissionKeys.ServiceTicket,
    },
  },
  {
    path: "/assets",
    name: "Assets",
    components: {
      default: () => import(/* webpackChunkName: "assets" */ "../views/Assets.vue"),
    },
    meta: {
      icon: CubeTransparentIcon,
      menu: "management",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.Assets,
    },
  },
  {
    path: "/assets/categories",
    name: "Assets Categories",
    components: {
      default: () =>
        import(/* webpackChunkName: "asset" */ "../views/AssetsCategories.vue"),
    },
    meta: {
      title: "Asset Categories",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.ManageKnowledgeBase,
    },
  },
  {
    path: "/assets/import",
    name: "Import Assets",
    components: {
      default: () => import(/* webpackChunkName: "asset" */ "../views/AssetsImport.vue"),
    },
    meta: {
      title: "Import Assets",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.ManageKnowledgeBase,
    },
  },
  {
    path: "/assets/export",
    name: "Export Assets",
    components: {
      default: () => import(/* webpackChunkName: "asset" */ "../views/AssetsExport.vue"),
    },
    meta: {
      title: "Export Assets",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.ManageKnowledgeBase,
    },
  },
  {
    path: "/assets/custom-fields",
    name: "Custom Asset Fields",
    components: {
      default: () =>
        import(/* webpackChunkName: "asset" */ "../views/AssetCustomFields.vue"),
    },
    meta: {
      title: "Custom Asset Fields",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.ManageKnowledgeBase,
    },
  },
  {
    path: "/asset/:id/:back?",
    name: "Asset",
    components: {
      default: () => import(/* webpackChunkName: "assets" */ "../views/Asset.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IAssetActionParam => {
        return {
          singleAssetId: route.params["id"],
          back: route.params["back"] ? route.params["back"] : null,
          singleAssetAction: "edit",
        };
      },
    },
    meta: {
      icon: CubeTransparentIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.asset, OPermissionKeys.assetMine], //OPermissionKeys.ServiceTicket,
    },
  },
  {
    path: "/assets/custom-field/:id/:back?",
    name: "Custom Asset Field",
    components: {
      default: () =>
        import(/* webpackChunkName: "assets" */ "../views/AssetCustomField.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IAssetFieldActionParam => {
        return {
          singleAssetFieldId: route.params["id"],
          back: route.params["back"] ? route.params["back"] : null,
          singleAssetFieldAction: "edit",
        };
      },
    },
    meta: {
      icon: CubeTransparentIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.asset], //OPermissionKeys.ServiceTicket,
    },
  },
  {
    path: "/articles",
    name: "Articles",
    components: {
      default: () => import(/* webpackChunkName: "articles" */ "../views/Articles.vue"),
    },
    meta: {
      icon: AcademicCapIcon,
      title: "Knowledge Base",
      menu: "management",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
    },
    children: [
      {
        path: "",
        name: "Articles All",
        components: {
          default: () =>
            import(/* webpackChunkName: "articles" */ "../views/ArticlesAll.vue"),
        },
        meta: {
          title: "Knowledge Base Articles",
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "new",
        name: "ArticleNew",
        components: {
          default: () =>
            import(/* webpackChunkName: "article" */ "../views/ArticlesAll.vue"),
        },
        props: {
          default: () => {
            return {
              knowledgeAction: "new",
            };
          },
        },
        meta: {
          title: "Knowledge Base Articles",
          menu: null,
          type: "overlay",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "view/:id",
        name: "ArticleView",
        components: {
          default: () =>
            import(/* webpackChunkName: "article" */ "../views/ArticlesAll.vue"),
        },
        props: {
          default: (route: RouteLocationNormalized): IKnowledgeActionParam => {
            return {
              knowledgeId: route.params["id"],
              knowledgeAction: "view",
            };
          },
        },
        meta: {
          title: "Knowledge Base Articles",
          menu: null,
          type: "overlay",
          requiresAuth: true,
          permission: [OPermissionKeys.article, OPermissionKeys.viewArticle], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "edit/:id",
        name: "ArticleEdit",
        components: {
          default: () =>
            import(/* webpackChunkName: "article" */ "../views/ArticlesAll.vue"),
        },
        props: {
          default: (route: RouteLocationNormalized): IKnowledgeActionParam => {
            return {
              knowledgeId: route.params["id"],
              knowledgeAction: "edit",
            };
          },
        },
        meta: {
          title: "Knowledge Base Articles",
          menu: null,
          type: "overlay",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "categories",
        name: "Articles Categories",
        components: {
          default: () =>
            import(/* webpackChunkName: "article" */ "../views/ArticlesCategories.vue"),
        },
        meta: {
          title: "Knowledge Base Articles",
          menu: null,
          type: "overlay",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "new2",
        name: "New Article",
        components: {
          default: () =>
            import(/* webpackChunkName: "articles" */ "../views/ArticlesNew.vue"),
        },
        meta: {
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
      {
        path: "edit2/:id",
        name: "Edit Article",
        components: {
          default: () =>
            import(/* webpackChunkName: "articles" */ "../views/ArticlesEdit.vue"),
        },
        meta: {
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article], //OPermissionKeys.ManageKnowledgeBase,
        },
      },
    ],
  },
  {
    path: "/article/:id/:back?",
    name: "Article",
    components: {
      default: () => import(/* webpackChunkName: "articles" */ "../views/Article.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IArticleActionParam => {
        return {
          singleArticleId: route.params["id"],
          back: route.params["back"] ? route.params["back"] : null,
          singleArticleAction: "edit",
        };
      },
    },
    meta: {
      icon: AcademicCapIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.article], //OPermissionKeys.ServiceTicket,
    },
  },
  {
    path: "/staffing",
    name: "On-boarding & Off-boarding",
    components: {
      default: () => import(/* webpackChunkName: "staffings" */ "../views/Staffings.vue"),
    },
    meta: {
      icon: BriefcaseIcon,
      //menu: 'icon-link',
      menu: "management",
      type: "menu",
      // guest: true,
      requiresAuth: true,
      menuTitle: "On-boarding &<br> Off-boarding",
      permission: [OPermissionKeys.asset],
    },
  },
  {
    path: "/staffing/:id",
    name: "Staffing",
    components: {
      default: () => import(/* webpackChunkName: "staffings" */ "../views/Staffing.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IStaffingActionParam => {
        return {
          singleStaffingId: route.params["id"],
          singleStaffingAction: (route.params["id"] === 'new')? "new":"edit",
        };
      },
    },
    meta: {
      icon: BriefcaseIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.asset],
      title: "On-boarding & Off-boarding"
    },
  },
  {
    path: "/service-catalogue",
    name: "Service Catalogues",
    components: {
      default: () =>
        import(/* webpackChunkName: "settings" */ "../views/ServiceCatalogueItems.vue"),
    },
    meta: {
      icon: BookOpenIcon,
      //menu: 'icon-link',
      menu: "management",
      type: "menu",
      // guest: true,
      requiresAuth: true,
      title: "Service Catalogue",
      permission: [OPermissionKeys.asset],
    },
  },
  {
    path: "/service-catalogue/:id/:back?",
    name: "Service Catalogue",
    components: {
      default: () =>
        import(/* webpackChunkName: "assets" */ "../views/ServiceCatalogueItem.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IServiceCatalogueItemActionParam => {
        return {
          singleServiceCatalogueItemId: route.params["id"],
          back: route.params["back"] ? route.params["back"] : null,
          singleServiceCatalogueItemAction: "edit",
        };
      },
    },
    meta: {
      icon: CubeTransparentIcon,
      menu: null,
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.asset, OPermissionKeys.assetMine], //OPermissionKeys.ServiceTicket,
    },
  },

  {
    path: "/settings",
    name: "Settings",
    components: {
      default: () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
    },
    meta: {
      icon: CogIcon,
      //menu: 'icon-link',
      menu: "management",
      type: "menu",
      // guest: true,
      requiresAuth: true,
      permission: [OPermissionKeys.organisation], //OPermissionKeys.Assets,
    },
    children: [
      {
        path: "organisation",
        alias: "",
        name: "Organisation",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "settings" */ "../views/SettingsOrganisation.vue"
            ),
        },
        meta: {
          icon: BuildingOfficeIcon,
          menu: "admin",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.organisation], //OPermissionKeys.Assets,
        },
      },
      {
        path: "users",
        name: "Users",
        components: {
          default: () =>
            import(/* webpackChunkName: "settings" */ "../views/SettingsUsers.vue"),
        },
        meta: {
          icon: UserGroupIcon,
          menu: "admin",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.user], // OPermissionKeys.UsersAndGroups,
        },
      },
      {
        path: "roles",
        name: "Roles",
        components: {
          default: () =>
            import(/* webpackChunkName: "settings" */ "../views/SettingsRoles.vue"),
        },
        meta: {
          icon: UserGroupIcon,
          menu: "admin",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.user], // OPermissionKeys.UsersAndGroups,
        },
      },
      {
        path: "support-email",
        alias: "",
        name: "Support Email",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "settings" */ "../views/SettingsSupportEmail.vue"
            ),
        },
        meta: {
          icon: BuildingOfficeIcon,
          menu: "admin",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.organisation], //OPermissionKeys.Assets,
        },
      },
      {
        path: "billing",
        name: "Billing",
        components: {
          default: () =>
            import(
              // /* webpackChunkName: "settings" */ "../views/SettingsBilling.vue"
              /* webpackChunkName: "settings" */ "../views/SettingsStripeBilling.vue"
              // /* webpackChunkName: "settings" */ "../views/SettingsStripeBillingNeo.vue"
            ),
        },
        meta: {
          icon: BanknotesIcon,
          menu: "admin",
          type: "menu",
          // guest: true
          requiresAuth: true,
          permission: [OPermissionKeys.billing],
        },
      },
      {
        path: "users/:id/:back?",
        name: "User",
        components: {
          default: () =>
            import(/* webpackChunkName: "settings" */ "../views/SettingsUser.vue"),
        },
        props: {
          default: (route: RouteLocationNormalized): ISettingsUserActionParam => {
            return {
              singleUserId: route.params["id"],
              back: route.params["back"] ? route.params["back"] : null,
              singleUserAction: "edit",
            };
          },
        },
        meta: {
          icon: UserGroupIcon,
          menu: null,
          type: "hidden",
          requiresAuth: true,
          permission: [OPermissionKeys.user], // OPermissionKeys.UsersAndGroups,
        },
      },
    ],
  },
  {
    path: "/settings/users/import",
    name: "Import Users",
    components: {
      default: () => import(/* webpackChunkName: "settings" */ "../views/UsersImport.vue"),
    },
    meta: {
      title: "Import Users",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.organisation],
    },
  },
  {
    path: "/settings/users/export",
    name: "Export Users",
    components: {
      default: () => import(/* webpackChunkName: "settings" */ "../views/UsersExport.vue"),
    },
    meta: {
      title: "Export Users",
      menu: null,
      type: "internal",
      requiresAuth: true,
      permission: [OPermissionKeys.organisation],
    },
  },
  {
    path: "/my-tickets",
    name: "My Tickets",
    components: {
      default: () => import(/* webpackChunkName: "settings" */ "../views/TicketsMine.vue"),
    },
    meta: {
      icon: TicketIcon,
      menu: "main",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.ticketMine],
    },
  },
  {
    path: "/my-assets",
    name: "My Assets",
    components: {
      default: () => import(/* webpackChunkName: "assets" */ "../views/AssetsMine.vue"),
    },
    meta: {
      icon: CubeTransparentIcon,
      menu: "main",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.assetMine], // OPermissionKeys.ViewOwnAssets,
    },
  },
  {
    path: "/knowledgebase",
    name: "Knowledge Base",
    components: {
      default: () =>
        import(/* webpackChunkName: "knowledgebase" */ "../views/KnowledgeBase.vue"),
    },
    meta: {
      icon: AcademicCapIcon,
      menu: "main",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.article, OPermissionKeys.viewArticle], // UseKnowledgeBase,
    },
    children: [
      {
        path: "",
        name: "Knowledge Base Home",
        components: {
          default: () =>
            import(/* webpackChunkName: "user" */ "../views/KnowledgeBaseHome.vue"),
        },
        meta: {
          title: "Knowledge Base",
          icon: AcademicCapIcon,
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article, OPermissionKeys.viewArticle],
        },
      },
      {
        path: "knowledge/:slug",
        name: "Knowledge Base Knowledge",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "assets" */ "../views/KnowledgeBaseKnowledge.vue"
            ),
        },
        meta: {
          title: "Knowledge Base",
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article, OPermissionKeys.viewArticle],
        },
      },
      {
        path: "knowledge/category/:key",
        name: "Knowledge Base Category",
        components: {
          default: () =>
            import(/* webpackChunkName: "assets" */ "../views/KnowledgeBaseCategory.vue"),
        },
        meta: {
          title: "Knowledge Base Category",
          menu: null,
          type: "internal",
          requiresAuth: true,
          permission: [OPermissionKeys.article, OPermissionKeys.viewArticle],
        },
      },
    ],
  },
  {
    path: "/user",
    name: "My Profile",
    components: {
      default: () => import(/* webpackChunkName: "user" */ "../views/UserProfile.vue"),
    },
    meta: {
      icon: UserCircleIcon,
      menu: "user",
      type: "menu",
      requiresAuth: true,
      permission: [OPermissionKeys.profileMine], // OPermissionKeys.UserProfile,
    },
    children: [
      {
        path: "",
        name: "Profile settings",
        components: {
          default: () =>
            import(/* webpackChunkName: "user" */ "../views/UserProfileSettings.vue"),
        },
        meta: {
          icon: UserCircleIcon,
          menu: "user-profile",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.profileMine],
        },
      },
      {
        path: "password",
        name: "Password settings",
        components: {
          default: () =>
            import(/* webpackChunkName: "user" */ "../views/UserProfilePassword.vue"),
        },
        meta: {
          icon: CogIcon,
          menu: "user-profile",
          type: "menu",
          requiresAuth: true,
          permission: [OPermissionKeys.profileMine],
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "../views/AuthLogin.vue"),
    },
    meta: {
      icon: ArrowLeftOnRectangleIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthForgotPassword.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthResetPassword.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): IRegisterActivateParam => {
        return { token: route.query.token };
      },
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    components: {
      default: () => import(/* webpackChunkName: "auth" */ "../views/AuthRegister.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
      theme: "green",
    },
  },
  {
    path: "/register/verify",
    name: "RegisterationVerify",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthRegisterVerification.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },
  {
    // path: "/register/activate/:token?",
    path: "/register/activate",
    name: "RegisterationActivateToken",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthRegisterActivate.vue"),
    },
    // props: route => ({ query: route.query })
    props: {
      default: (route: RouteLocationNormalized): IRegisterActivateParam => {
        return { token: route.query.token };
      },
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },

  {
    path: "/register/organisation",
    name: "RegisterationOrganisation",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthRegisterOrganisation.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: null,
      type: "internal",
      guest: true,
      // requiresAuth: true,
      // permission: [OPermissionKeys.UserProfile],
    },
  },

  {
    path: "/register/subscription",
    name: "Subscription",
    components: {
      default: () =>
        import(/* webpackChunkName: "settings" */ "../views/SelectSubscription.vue"),
    },
    meta: {
      icon: HomeIcon,
      menu: null,
      type: "internal",
      guest: true,
      // permission: [OPermissionKeys.billing],
    },
  },

  {
    path: "/verification/:code?",
    name: "Verification",
    components: {
      default: () =>
        import(/* webpackChunkName: "auth" */ "../views/AuthVerification.vue"),
    },
    props: {
      default: (route: RouteLocationNormalized): INewTicketActionParam => {
        return {
          code: route.params["code"] ? route.params["code"] : null,
        };
      },
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "menu",
      guest: true,
    },
  },
  {
    path: "/404",
    name: "Not Found",
    components: {
      default: () => import(/* webpackChunkName: "generic" */ "../views/Error.vue"),
    },
    props: {
      default: () => {
        return {
          code: "404",
        };
      },
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "hidden",
    },
  },
  {
    path: "/403/:pathMatch(.*)*",
    name: "Forbidden",
    components: {
      default: () => import(/* webpackChunkName: "generic" */ "../views/Error.vue"),
    },
    props: {
      default: () => {
        return {
          code: "403",
        };
      },
    },
    meta: {
      icon: HomeIcon,
      menu: "notauth",
      type: "hidden",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Scroll to the saved position on popstate navigation.
    } else {
      return { top: 0 }; // Scroll to the top on every route change.
    }
  },
});

router.beforeEach(async (to, from) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state?.auth?.isAuthenticated) {
      console.log("router.beforeEach isAuthenticated lets checkPermission");
      return checkPermission(to);
    }
    const { usersLoadPromisse, rolesLoadPromisse, permissionsLoadPromisse } = userRoles(
      true
    );
    await Promise.all([usersLoadPromisse, rolesLoadPromisse, permissionsLoadPromisse]);

    const isAuthenticated = store.state.auth.isAuthenticated;
    if (isAuthenticated) {
      console.log("router.beforeEach isAuthenticated lets checkPermission");
      return checkPermission(to);
    }
    if (window.location.pathname != "/login") return "/login";
  }
});

let firstLoad = true;
const checkPermission = async (to: RouteLocationNormalized) => {
  const { userLoadPromisse, rolesLoadPromisse, permissionsLoadPromisse } = userRoles(
    firstLoad ? true : false
  );
  firstLoad = false;

  const {
    StripePaymentMethodLoadPromisse,
    StripeCustomerLoadPromisse,
    StripeSubscriptionLoadPromisse,
    StripeInvoiceLoadPromisse,
    StripePlanAndProductLoadPromisse,
  } = paymentComposable(true);

  await Promise.all([
    userLoadPromisse,
    rolesLoadPromisse,
    permissionsLoadPromisse,
    StripePaymentMethodLoadPromisse,
    StripeCustomerLoadPromisse,
    StripeSubscriptionLoadPromisse,
    StripeInvoiceLoadPromisse,
    StripePlanAndProductLoadPromisse,
  ]);
  const { user } = userRoles(false);
  let _hasPermission = false; // undefined as RolePermission | undefined;
  if (to?.meta?.permission) {
    if (to?.meta.permission == "childs") {
      let perms = [] as Array<PermissionKeys | null>;

      const rawTo = routes.find((r) => r.path == to.path);
      rawTo?.children?.forEach((c: RouteRecordRaw) => {
        perms = perms.concat(c?.meta?.permission as PermissionKeys[] | null);
      });

      _hasPermission = perms
        .map((p) => hasPermission(p as PermissionKeys[] | null))
        .reduce((q, p) => q || p);
    } else {
      _hasPermission = hasPermission(to.meta.permission as PermissionKeys[] | null);
    }
  }

  if (_hasPermission === false) {
    return `/403/${to.path}`;
  } else if (_hasPermission !== undefined) {
    if (user?.organisation == null) {
      return "/register/organisation";
    } else {
      if (!store.state?.auth?.hasSubscription) {
        return "/register/subscription";
      }
    }
    return;
  }

  return `/403/${to.path}`;
};

export default router;
